var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "center" } }, [
    _c("br"),
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_pulsante_abilita_enabled,
            expression: "!is_pulsante_abilita_enabled",
          },
        ],
        staticClass:
          "text-center justify-center q-pa-md q-gutter-sm fixed-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-6 col-12 q-pr-md", attrs: { align: "left" } },
        [
          _c(
            "q-list",
            { attrs: { separator: "" } },
            [
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Prodotto:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.id_prodotto)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Numero preventivo:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.guid_preventivo)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Descrizione preventivo:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.nominativo)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Stato della quotazione:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.stato)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Unità Operativa:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.unita_operativa)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Operatore:")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quotazione.operatore)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4 col-12 q-pr-md", attrs: { align: "left" } },
        [
          _c("h5", { staticClass: "q-mt-sm q-pt-sm" }, [
            _vm._v("Dati del Contratto"),
          ]),
          _c("q-select", {
            staticStyle: { margin: "4px" },
            attrs: {
              outlined: "",
              "error-message": "Il settore deve essere valorizzato",
              options: _vm.elenco_settori,
              label: "Settore Prodotto",
              name: "settore_prodotto",
              "virtual-scroll-slice-size": "0",
              "virtual-scroll-item-size": "0",
            },
            on: {
              input: function ($event) {
                return _vm.onSelectSettore()
              },
            },
            model: {
              value: _vm.settore,
              callback: function ($$v) {
                _vm.settore = $$v
              },
              expression: "settore",
            },
          }),
          _c("q-select", {
            staticStyle: { margin: "4px" },
            attrs: {
              outlined: "",
              "error-message": "Il prodotto deve essere valorizzato",
              options: _vm.elenco_prodotti,
              label: "Prodotto a catalogo",
              name: "prodotto_catalogo",
              "virtual-scroll-slice-size": "0",
              "virtual-scroll-item-size": "0",
            },
            on: {
              input: function ($event) {
                return _vm.onSelectProdotto()
              },
            },
            model: {
              value: _vm.prodotto_da_catalogo,
              callback: function ($$v) {
                _vm.prodotto_da_catalogo = $$v
              },
              expression: "prodotto_da_catalogo",
            },
          }),
          _c("q-input", {
            staticStyle: { margin: "4px" },
            attrs: { outlined: "", label: "Compagnia", readonly: "" },
            model: {
              value: _vm.compagnia,
              callback: function ($$v) {
                _vm.compagnia = $$v
              },
              expression: "compagnia",
            },
          }),
          _c("q-input", {
            staticStyle: { margin: "4px" },
            attrs: { outlined: "", label: "Tariffa", readonly: "" },
            model: {
              value: _vm.tariffa,
              callback: function ($$v) {
                _vm.tariffa = $$v
              },
              expression: "tariffa",
            },
          }),
          _c("q-input", {
            staticStyle: { margin: "4px" },
            attrs: { outlined: "", label: "Fornitore", readonly: "" },
            model: {
              value: _vm.fornitore,
              callback: function ($$v) {
                _vm.fornitore = $$v
              },
              expression: "fornitore",
            },
          }),
          _c("q-select", {
            staticStyle: { margin: "4px" },
            attrs: {
              outlined: "",
              name: "tipo_rateazione",
              "virtual-scroll-slice-size": "0",
              "virtual-scroll-item-size": "0",
              "error-message": "La rateazione deve essere valorizzata",
              options: _vm.rate_premio,
              label: "Tipo di Rateazione del premio",
            },
            model: {
              value: _vm.rata,
              callback: function ($$v) {
                _vm.rata = $$v
              },
              expression: "rata",
            },
          }),
          _c("q-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.DurataAnniIsVisible(),
                expression: "DurataAnniIsVisible()",
              },
            ],
            staticStyle: { margin: "4px" },
            attrs: {
              outlined: "",
              "error-message":
                "La durata del contratto deve essere valorizzata",
              options: _vm.durata_contratto,
              name: "durata_contratto",
              "virtual-scroll-slice-size": "0",
              "virtual-scroll-item-size": "0",
              label: "Anni durata Premio Unico",
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [_vm._v(" Anni ")]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.durata_anni,
              callback: function ($$v) {
                _vm.durata_anni = $$v
              },
              expression: "durata_anni",
            },
          }),
          _c("q-input", {
            staticStyle: { margin: "4px" },
            attrs: {
              outlined: "",
              "error-message": "Il premio del preventivo deve essere inserito",
              label: _vm.label_premio,
              type: "number",
              name: "premio_annuale",
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [_vm._v(" Euro ")]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.premio,
              callback: function ($$v) {
                _vm.premio = $$v
              },
              expression: "premio",
            },
          }),
          _c("q-input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.PremioSemestraleIsVisible(),
                expression: "PremioSemestraleIsVisible()",
              },
            ],
            staticStyle: { margin: "4px" },
            attrs: {
              outlined: "",
              "error-message": "Il premio del preventivo deve essere inserito",
              label: "Premio SEMESTRALE del Preventivo",
              type: "number",
              name: "premio_semestrale",
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [_vm._v(" Euro ")]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.premio_semestrale,
              callback: function ($$v) {
                _vm.premio_semestrale = $$v
              },
              expression: "premio_semestrale",
            },
          }),
          _c("br"),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("hr"),
    _c("div", { staticClass: "row", attrs: { align: "center" } }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "INDIETRO",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onIndietroClicked.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col-12 col-md-6",
          attrs: {
            align: "center",
            disabled: !_vm.is_pulsante_abilita_enabled,
          },
        },
        [
          _c("QQButton", {
            attrs: {
              label: "DEFINISCI QUOTAZIONE",
              color: "blue-grey",
              icon: "mdi-content-save-outline",
              size: "md",
              icon_align: "right",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onRegistraDefinizione.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Definisci quotazione")]),
      _c("br"),
      _c("br"),
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }